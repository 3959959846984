/* =========================================================
   1. General Styles
========================================================= */
body {
  font-family: 'Raleway', sans-serif; /* Fuente Raleway como predeterminada */
  margin: 0;
  padding: 0;
}

/* =========================================================
   2. Header Styles
========================================================= */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #fff;
  border-bottom: 1px solid #ddd; /* Línea debajo del encabezado */
}

.logo {
  display: flex; /* Organiza el logo y el texto en fila */
  align-items: center; /* Alinea verticalmente el texto y la imagen */
}

.logo-image {
  height: 50px; /* Tamaño del logo */
  width: auto; /* Mantiene las proporciones */
}

.logo-text {
  margin-left: 10px; /* Espacio entre el logo y el texto */
  font-size: 1.5rem; /* Tamaño del texto */
  font-weight: bold; /* Texto en negrita */
  color: #333; /* Color del texto */
}

.nav-links a {
  margin: 0 15px; /* Espaciado entre los enlaces */
  text-decoration: none; /* Quita el subrayado */
  color: #333; /* Color del texto */
  font-size: 1rem; /* Tamaño del texto */
}

.nav-links a:hover {
  color: #ff8c00; /* Color cuando pasas el mouse */
}

/* =========================================================
   3. Main Content Styles
========================================================= */
.main-content {
  height: 100vh; /* Ocupa toda la altura de la pantalla */
  position: relative; /* Para posicionar el texto sobre el fondo */
  overflow: hidden; /* Oculta cualquier contenido que sobresalga */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Fondo dinámico */
.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%; /* Altura específica del carrusel */
  background: black;
  background: linear-gradient(to right, transparent 20%, #002244 20%); /* Combina imagen y fondo */
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  z-index: 1;
  transition: background-image 1s ease-in-out; /* Transición suave */
}

/* =========================================================
   4. Carousel Styles
========================================================= */
.left-column {
  flex: 1;
  overflow: hidden; /* Oculta cualquier desbordamiento */
  position: relative;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta la imagen sin distorsión */
  transition: opacity 1s ease-in-out; /* Transición suave al cambiar de imagen */
  position: absolute;
  top: 0;
  left: 0;
}

/* =========================================================
   5. Text Overlay Styles
========================================================= */
.text-overlay {
  position: relative; /* Sobre el fondo */
  z-index: 2; /* Asegura que esté delante del fondo */
  color: white; /* Contraste claro */
  text-align: left; /* Alinea el texto a la izquierda dentro del contenedor */
  max-width: 600px; /* Limita el ancho máximo del texto */
  padding: 20px;
  margin-left: auto; /* Empuja el texto hacia la derecha */
  margin-right: 50px; /* Espacio desde el borde derecho */
  top: 50%; /* Centra verticalmente */
  transform: translateY(-50%); /* Ajuste perfecto al centro vertical */
  background: rgba(0, 0, 0, 0.5); /* Fondo negro semi-transparente */
  border-radius: 10px; /* Bordes redondeados */
}

.text-overlay h1 {
  font-size: 2.5rem; /* Tamaño del título */
  margin-bottom: 20px;
}

.text-overlay p {
  font-size: 1.5rem; /* Tamaño del texto */
  line-height: 1.8; /* Espaciado entre líneas */
  margin-bottom: 30px;
}

.cta-button {
  background-color: #007bff; /* Azul brillante */
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem; /* Botón ligeramente más grande */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3; /* Azul más oscuro */
}

/* =========================================================
   6. Responsiveness
========================================================= */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Apila el logo y los enlaces verticalmente */
    align-items: flex-start; /* Alinea los elementos al inicio */
    padding: 20px; /* Ajusta el padding */
  }

  .nav-links {
    display: flex;
    flex-wrap: wrap; /* Permite que los enlaces se ajusten si no caben en una fila */
    gap: 10px; /* Espaciado entre enlaces */
    margin-top: 10px;
  }

  .main-content {
    flex-direction: column; /* Apila la imagen y el texto */
    text-align: center; /* Centra el texto */
    padding: 20px; /* Reduce el espacio interno */
  }

  .background-container {
    height: 30vh; /* Reduce la altura en pantallas pequeñas */
  }

  .text-overlay {
    text-align: center;
    margin-left: auto;
    margin-right: auto; /* Centra el texto */
    max-width: 90%; /* Reduce el ancho del texto */
  }
}